import React, {useState} from 'react'
import { Helmet } from "react-helmet"
import axios from 'axios'
import styles from '../styles/index.css'

export default function Index({styles, referer}) {
    const [state, setState] = useState({requested: false, email: ''})

    const onEmailChanged = (e) => {
        // console.log('e', e)
        setState({...state, email: e.target.value})
    }

    const onEarlyAccessRequested = async (e) => {
        // console.log(state.email)
        e.preventDefault()
        e.stopPropagation()
        if(!state.email) {
            return
        }
        axios.post('/api/subscribe', {email: state.email, referer})
        setState({requested: true, email: ''})
    }

    return (
        <div>
            <Helmet>
                <title>MemberGenie</title>
                <link rel="icon" href="/favicon.ico"/>
                <link rel="stylesheet" href="https://rsms.me/inter/inter.css"/>
            </Helmet>
            <div className="relative bg-white overflow-hidden">
                <div className="max-w-screen-xl mx-auto">
                    <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                        <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                             fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <polygon points="50,0 100,0 50,100 0,100"/>
                        </svg>

                        <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
                                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                    <div className="flex items-center justify-between w-full md:w-auto">
                                        <svg viewBox="0 0 20 20" fill="currentColor" className="user w-6 h-6">
                                            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                    </div>
                                </div>
                                {/*<div className="hidden md:block md:ml-10 md:pr-4">*/}
                                {/*  <a href="/"*/}
                                {/*     className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">MemberGenie</a>*/}
                                {/*</div>*/}
                                <div className="block md:ml-10 md:pr-4">
                                    <a href="/"
                                       className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">MemberGenie</a>
                                </div>
                            </nav>
                        </div>

                        {
                            // Mobile menu, show/hide based on menu open state.
                            //
                            // Entering: "duration-150 ease-out"
                            // From: "opacity-0 scale-95"
                            // To: "opacity-100 scale-100"
                            // Leaving: "duration-100 ease-in"
                            // From: "opacity-100 scale-100"
                            // To: "opacity-0 scale-95"
                        }

                        <main
                            className="relative mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-8 lg:mt-12 lg:px-8 xl:mt-15">
                            <div className="text-left">
                                <h2
                                    className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                                    Memberships
                                    <div className="text-gray-600"> for small biz</div>
                                </h2>
                                {
                                    !state.requested && (
                                        <div className="bg-white">
                                            <div className="py-8">
                                                <form className="sm:flex">
                                                    <input aria-label="Email address" type="email" required
                                                           className="appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:max-w-xs"
                                                           placeholder="Enter your email"
                                                           value={state.email}
                                                           onChange={onEmailChanged}
                                                    />
                                                    <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                                        <button onClick={onEarlyAccessRequested}
                                                                className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                                            Notify Me
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )
                                }
                                <p
                                    className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit
                                    eaque, iste dolor cupiditate blanditiis ratione.
                                </p>

                                {
                                    state.requested && (
                                        <div className="mt-8 flex items-center bg-gray-500 text-white text-sm font-bold px-4 py-3"
                                             role="alert">
                                            <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path
                                                    d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/>
                                            </svg>
                                            <p>Thank you for your interest! We'll stay in touch as we progress.</p>
                                        </div>
                                    )
                                }
                            </div>
                        </main>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/3">
                    <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                         src="https://images.unsplash.com/photo-1517637382994-f02da38c6728?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3300&q=80"
                         alt=""/>
                </div>
            </div>

            <div className="py-12 bg-white">
                <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="lg:text-center">
                        <p className="text-base leading-6 text-gray-600 font-semibold tracking-wide uppercase">Benefits</p>
                        <h3
                            className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                            A better way to engage your members
                        </h3>
                        <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                            accusamus quisquam.
                        </p>
                    </div>

                    <div className="mt-10">
                        <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
                            <li>
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-500 text-white">
                                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg leading-6 font-medium text-gray-900">Benefit number one</h4>
                                        <p className="mt-2 text-base leading-6 text-gray-500">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit
                                            eaque, iste dolor cupiditate blanditiis ratione.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-10 md:mt-0">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-500 text-white">
                                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg leading-6 font-medium text-gray-900">Benefit number two</h4>
                                        <p className="mt-2 text-base leading-6 text-gray-500">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit
                                            eaque, iste dolor cupiditate blanditiis ratione.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-10 md:mt-0">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-500 text-white">
                                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M13 10V3L4 14h7v7l9-11h-7z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg leading-6 font-medium text-gray-900">Benefit number three</h4>
                                        <p className="mt-2 text-base leading-6 text-gray-500">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit
                                            eaque, iste dolor cupiditate blanditiis ratione.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-10 md:mt-0">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-500 text-white">
                                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg leading-6 font-medium text-gray-900">Benefit number </h4>
                                        <p className="mt-2 text-base leading-6 text-gray-500">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit
                                            eaque, iste dolor cupiditate blanditiis ratione.
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                    <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                        Ready to dive in?
                        <br/>
                        Start your free trial today.
                    </h2>
                    <div className="mt-8 flex justify-center">
                        {
                            !state.requested && (
                                <div className="bg-white">
                                    <div className="py-8">
                                        <form className="sm:flex">
                                            <input aria-label="Email address" type="email" required
                                                   className="appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:max-w-xs"
                                                   placeholder="Enter your email"
                                                   value={state.email}
                                                   onChange={onEmailChanged}
                                            />
                                            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                                <button onClick={onEarlyAccessRequested}
                                                        className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                                    Notify Me
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                        }

                        {
                            state.requested && (
                                <div className="mt-8 flex items-center bg-gray-500 text-white text-sm font-bold px-4 py-3"
                                     role="alert">
                                    <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/>
                                    </svg>
                                    <p>Thank you for your interest! We'll stay in touch as we progress.</p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export async function getServerSideProps(context) {
    const {headers} = context.req
    const referer = headers.referer || headers.Referer || headers.Referrer || headers.referrer || null
    return {
        props: {
            referer
        }, // will be passed to the page component as props
    }
}
